.App{
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.AppHeader {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 20px 4px 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.AppFooter {
  height: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.SideMenuAndPageContent {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
}

.SideMenu {

}

.PageContent {
  padding-left: 12px;
}
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: #f4e8dc;
  height: 100vh;
}

a {
  text-decoration: none;
  color: inherit;
}

.container {
  max-width: 250rem;
  margin: 0 auto;
  height: 100%;
  padding: 0 40px;
}

.navbar {
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-menu__wrapper {
  display: flex;
  column-gap: 140px;
  font-weight: 600;
  font-size: 20px;
}

.navbar-menu__small-device {
  display: none;
}

.navbar__menu {
  display: flex;
  column-gap: 92.14px;
  align-items: center;
}

.navbar__menu a {
  text-transform: uppercase;
  text-decoration: none;
  color: #606060;
}

.navbar__menu a.active {
  color: black;
}

.navbar__auth {
  display: flex;
  column-gap: 15px;
  align-items: center;
}

.sign-in {
  background-color: black;
  border-radius: 20px;
  padding: 5px 22px;
  color: white;
}

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  min-height: calc(100% - 117px);
}

.snippets {
  color: #9c9c9c;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  margin-left: 50px;
  margin-right: 50px;
}

.title {
  font-size: 60px;
  font-weight: 800;
  width: 690px;
  line-height: 80px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 15px;
}

.summer {
  display: inline;
  color: #106e33;
}

.description {
  width: 690px;
  color: #5b5b5b;
  text-align: justify;
  font-size: 20px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 30px;
}

.descriptionaboutus {
  color: #5b5b5b;
  text-align: justify;
  font-size: 20px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 30px;
}

.order-now {
  background-color: #106e33;
  border: none;
  border-radius: 30px;
  padding: 14px 35px;
  color: white;
  font-size: 20px;
  font-weight: 600;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 30px;
}

.social-media {
  display: flex;
  width: 172.25px;
  justify-content: space-around;
}

.social-media a {
  display: flex;
  justify-content: center;
}

.hero__image img {
  width: 100%;
  height: 100%;
}

.background {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.background img {
  display: block;
  width: 100%;
  height: 100%;
  filter: blur(2px);
}

@media screen and (max-width: 1439px) {
  .container {
    max-width: 65rem;
  }

  .navbar-menu__wrapper {
    column-gap: 30px;
  }

  .hero {
    column-gap: 30px;
  }

  .hero__content {
    font-size: 14px;
  }

  .snippets,
  .description,
  .order-now {
    font-size: inherit;
  }

  .title,
  .description {
    width: 400px;
  }

  .title {
    font-size: 50px;
    line-height: 60px;
  }
}

@media screen and (max-width: 1023px) {
  .navbar {
    padding-top: 20px;
  }

  .navbar-menu__wrapper {
    font-size: 14px;
    column-gap: 50px;
  }

  .navbar__menu {
    column-gap: 45px;
  }

  .navbar__logo img {
    width: 40px;
    height: 40px;
  }

  .hero {
    min-height: calc(100% - 67px);
  }

  .hero__image img {
    width: 257px;
    height: 374px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 0 25px;
  }

  .navbar-menu__wrapper {
    display: none;
  }

  .navbar-menu__small-device {
    display: block;
  }

  .hero {
    flex-direction: column;
    align-items: unset;
  }

  .title,
  .description {
    width: 80%;
  }

  .description {
    margin-bottom: 20px;
    margin-right: 30px;
  }

  .order-now {
    margin-bottom: 20px;
  }

  .social-media {
    width: 141.58px;
    height: 20px;
  }

  .hero__image {
    text-align: right;
  }
}

@media screen and (max-width: 424px) {
  .title {
    font-size: 40px;
    line-height: 45px;
  }
}

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,500,300,700);

* {
  font-family: Open Sans;
}

section {
  width: 100%;
  display: inline-block;
  background: #333;
  height: 50vh;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  text-decoration: underline;
}

.footer-distributed{
	background: #666;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
	box-sizing: border-box;
	width: 100%;
	text-align: left;
	font: bold 16px sans-serif;
	padding: 55px 50px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right{
	display: inline-block;
	vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left{
	width: 100%;
}

/* The company logo */

.footer-distributed h3{
	color:  #ffffff;
	font: normal 36px 'Open Sans', cursive;
	margin: 0;
}

.footer-distributed h3 span{
	color:  lightseagreen;
}

/* Footer links */

.footer-distributed .footer-links{
	color:  #ffffff;
	margin: 20px 0 12px;
	padding: 0;
}

.footer-distributed .footer-links a{
	display:inline-block;
	line-height: 1.8;
  font-weight:400;
	text-decoration: none;
	color:  inherit;
}

.footer-distributed .footer-company-name{
	color:  #222;
	font-size: 14px;
	font-weight: normal;
	margin: 0;
}

/* Footer Center */

.footer-distributed .footer-center{
	width: 35%;
}

.footer-distributed .footer-center i{
	background-color:  #33383b;
	color: #ffffff;
	font-size: 25px;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	text-align: center;
	line-height: 42px;
	margin: 10px 15px;
	vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope{
	font-size: 17px;
	line-height: 38px;
}

.footer-distributed .footer-center p{
	display: inline-block;
	color: #ffffff;
  font-weight:400;
	vertical-align: middle;
	margin:0;
}

.footer-distributed .footer-center p span{
	display:block;
	font-weight: normal;
	font-size:14px;
	line-height:2;
}

.footer-distributed .footer-center p a{
	color:  lightseagreen;
	text-decoration: none;;
}

.footer-distributed .footer-links a:before {
  content: "|";
  font-weight:300;
  font-size: 20px;
  left: 0;
  color: #fff;
  display: inline-block;
  padding-right: 5px;
}

.footer-distributed .footer-links .link-1:before {
  content: none;
}

/* Footer Right */

.footer-distributed .footer-right{
	width: 20%;
}

.footer-distributed .footer-company-about{
	line-height: 20px;
	color:  #92999f;
	font-size: 13px;
	font-weight: normal;
	margin: 0;
}

.footer-distributed .footer-company-about span{
	display: block;
	color:  #ffffff;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 20px;
}

.footer-distributed .footer-icons{
	margin-top: 25px;
}

.footer-distributed .footer-icons a{
	display: inline-block;
	width: 35px;
	height: 35px;
	cursor: pointer;
	background-color:  #33383b;
	border-radius: 2px;

	font-size: 20px;
	color: #ffffff;
	text-align: center;
	line-height: 35px;

	margin-right: 3px;
	margin-bottom: 5px;
}

html {
  overflow-x: initial !important;
}
